import { render, staticRenderFns } from "./CourseDetail.vue?vue&type=template&id=924bc274&scoped=true&"
import script from "./CourseDetail.vue?vue&type=script&lang=js&"
export * from "./CourseDetail.vue?vue&type=script&lang=js&"
import style0 from "./CourseDetail.vue?vue&type=style&index=0&id=924bc274&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "924bc274",
  null
  
)

export default component.exports