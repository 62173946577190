<template>
  <a-spin :spinning="spinning">
    <div class="btn-back" @click="goBack()">
      <a-icon type="arrow-left" /> 返回
    </div>
    <a-row :gutter="[16, 16]">
      <!-- 课件基本信息 -->
      <a-col :span="24">
        <a-card>
          <template slot="title">基本信息</template>
          <a-row 
            type="flex" 
            justify="space-between" 
            :gutter="16">
            <!-- 基础信息 -->
            <a-col :span="18">
              <a-form-model
                ref="basicForm"
                :model="basic"
                :rules="rules"
                class="basic-info">
                <a-row>
                  <a-col :xl="24" :xxl="16">
                    <a-form-model-item 
                      label="课件名称" 
                      prop="name"
                      :label-col="{ span: 3 }"
                      :wrapper-col="{ span: 20 }">
                      <a-input 
                        v-model="basic.name" 
                        :max-length="20"
                        allow-clear
                        placeholder="课件名称不能超过20个字符">
                      </a-input>
                    </a-form-model-item>
                  </a-col>

                  <a-col :xl="12" :xxl="8">
                    <a-form-model-item 
                      label="课件类型" 
                      prop="levels"
                      :label-col="{ span: 6 }"
                      :wrapper-col="{ span: 15 }">
                      <a-cascader
                        v-model="basic.levels"
                        :options="levels"
                        :field-names="{ label: 'name', value: 'kid', children: 'sub' }"
                        placeholder="课件类型/课件层级"
                        :getPopupContainer="(triggerNode) => {
                          return triggerNode.parentNode || document.body;
                        }"
                        @change="selectLevel">
                        <!-- expand-trigger="hover" -->
                      </a-cascader>
                    </a-form-model-item>
                  </a-col>

                  <a-col :xl="12" :xxl="8" v-if="showTypes">
                    <a-form-model-item 
                      label="选择工种" 
                      prop="wt_id"
                      :label-col="{ span: 6 }"
                      :wrapper-col="{ span: 16 }">
                      <a-select 
                        v-model="basic.wt_id"
                        placeholder="请选择工种"
                        :getPopupContainer="(triggerNode) => {
                          return triggerNode.parentNode || document.body;
                        }"
                        allow-clear>
                        <a-select-option
                          v-for="worktype in workTypes"
                          :key="worktype.kid">
                          {{ worktype.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>

                <a-row v-if="showTypes">
                  <a-col>
                    <a-form-model-item 
                      label="关联工种" 
                      prop="wt_ids"
                      :label-col="{ xl: 3, xxl: 2 }"
                      :wrapper-col="{ xl: 20, xxl: 21 }"
                       class="relative">
                      <a-checkbox-group 
                        v-model="basic.wt_ids"
                        :options="collapse ? relativeTypes.slice(0, 9) : relativeTypes">
                      </a-checkbox-group>

                      <a-button type="link" @click="collapse = !collapse" class="btn-collapse">
                        {{ collapse ? '展开' : '收起' }}
                        <a-icon :type="collapse? 'down' : 'up'" />
                      </a-button>
                    </a-form-model-item>
                  </a-col>
                </a-row>

                <a-row>
                  <a-col>
                    <a-form-model-item 
                      label="课件描述" 
                      prop="detail"
                      :label-col="{ xl: 3, xxl: 2 }"
                      :wrapper-col="{ xl: 20, xxl: 21 }">
                      <a-textarea 
                        v-model="basic.detail" 
                        allow-clear
                        :max-length="200"
                        :auto-size="{ minRows: 4 }"
                        placeholder="最多可写200字">
                      </a-textarea>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                
                <a-row v-show="false">
                  <a-form-model-item prop="file_img">
                    <a-input v-model="basic.file_img"></a-input>
                  </a-form-model-item>
                  <a-form-model-item prop="file_path">
                    <a-input v-model="basic.file_path"></a-input>
                  </a-form-model-item>
                  <a-form-model-item prop="exam_ids">
                    <a-input v-model="basic.exam_ids"></a-input>
                  </a-form-model-item>
                </a-row>
              </a-form-model>
            </a-col>
            <!-- 封面图 -->
            <a-col :span="6">
              <div class="cover-img">
                <img 
                :src="basic.file_img? basic.cover_view : require('../../assets/images/planet.png')" 
                :class="{ 'is-error': checkRules.file_img && (basic.file_img == '') }">
                <div class="hint">*封面图为必填项</div>
                <a-upload 
                  :showUploadList="false"
                  :customRequest="(file) => uploadFile(file, 'cover')">
                  <a-button type="primary" icon="upload">上传封面图</a-button>
                </a-upload>
              </div>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    <a-row :gutter="[16, 16]" type="flex">
      <!-- 视频 -->
      <a-col :span="8">
        <a-card :class="['course-file', { 'is-error': checkRules.file_path && (basic.file_path =='') }]">
          <template slot="title">
            <span class="title-name">课件文件</span>
          </template>
          <div v-if="basic.file_path" id="dplayer"></div>
          <img v-else src="../../assets/images/course.png" />
          <a-upload 
            :showUploadList="false"
            :customRequest="(file) => uploadFile(file, 'video')">
            <a-button type="primary">上传视频课件</a-button>
          </a-upload>
          <a-progress
            v-if="videoProgress > 0"
            :percent="videoProgress"
            status="active"
            :stroke-color="{ from: '#6DD5FA', to: '#2980B9' }"></a-progress>
        </a-card>
      </a-col>
    <!-- 试题 -->
      <a-col :span="16">
        <a-card :class="['main-content', { 'is-error': checkRules.exam_ids && (list.length == 0) }]">
          <template slot="title">
            <span class="title-name">试题</span> 
            <a-button type="primary" icon="plus" @click="showModal('add')">添加试题</a-button>
          </template>

          <ul v-if="list.length > 0" class="exam">
            <li v-for="(item, index) in list" :key="item.kid">
              <div class="list-item">
                <!-- 排序 -->
                <div class="seq">
                  <span class="number">{{ 9 > index ? '0' + (index+1) : (index+1) }}</span>
                  <a-tag :color="item.sort == 1 ? '#2db7f5' : '#f50'">
                    {{ item.sort == 1 ? '选择题' : '判断题' }}
                  </a-tag>
                </div>
                <!-- 试题 -->
                <div class="ques">
                  <div class="ques-title">
                    <b>【{{item.score}}分】</b>{{item.title}}
                  </div>
                  <div class="ques-detail">
                    <span>选项：</span>
                    <ul class="options">
                      <li v-for="(opt, idx) in item.opts" :key="idx">
                        <span v-if="item.sort == '1'">{{String.fromCharCode(65+idx)}}.</span> 
                        {{opt}}
                      </li>
                    </ul>
                  </div>
                  <div class="ques-detail">
                    <span>答案：</span>
                    <span v-if="item.sort === '1'" class="answer">{{String.fromCharCode(65 + Number(item.correct))}}</span>
                    <span v-else class="answer">{{item.opts[item.correct]}}</span>
                  </div>
                </div>
                <!-- 配图 -->
                <div class="ques-cover" >
                  <img v-if="item.path_img" :src="item.img_view">
                </div>
              </div>
              <div class="list-action">
                <span class="edit-action" @click="showModal('edit', item.kid, index)">编辑</span>
                <span class="del-action" @click="delQues(item.kid, index)">删除</span>
              </div>
            </li>
          </ul>

          <a-empty
            v-else 
            :image="require('../../assets/images/empty.png')" 
            description="暂无试题"></a-empty>
        </a-card>
      </a-col>
    </a-row>

    <a-button 
      type="primary" 
      size="large"
      class="btn-submit" 
      :loading="submitLoading"
      @click="submit">{{ submitLoading? '上传文件中' : '保 存'  }}</a-button>


    <!-- 弹框 -->
    <a-modal
      :title="modalType == 'add'?  '添加试题' : '编辑试题'"
      :width="600"
      :visible="dialog"
      :confirm-loading="confirmLoading"
      :okText="okBtnText"
      @ok="modalConfirm()"
      @cancel="() => {
        this.$refs.modalForm.resetFields();
        this.confirmLoading = false
        this.dialog = false;
      }">
      <a-form-model
        ref="modalForm"
        :model="form"
        :rules="modalRules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 18 }">
          <a-form-model-item label="试题类型" prop="sort">
            <a-radio-group 
              v-model="form.sort" 
              @change="() => {
                this.$refs.modalForm.clearValidate()
                if (this.form.sort == 1) {
                  this.form.opts = ['', '', '', '']
                } 
              }">
              <a-radio value="1">单选题</a-radio>
              <a-radio value="2">判断题</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item label="试题题目" prop="title">
            <a-textarea 
              v-model.trim="form.title" 
              allow-clear
              :auto-size="{ minRows: 2 }"
              :max-length="60"
              placeholder="题目长度不能超过60字">
            </a-textarea>
          </a-form-model-item>

          <a-form-model-item label="选项" prop="correct">
            <template v-if="form.sort == 1">
              <a-radio-group v-model="form.correct" class="radio-group">
                <a-radio 
                  v-for="(item, index) in form.opts" 
                  :key="index"
                  :value="String(index)">
                    <a-form-model-item 
                      :prop="'opts[' + index+ ']'" 
                      :rules="modalRules.value">
                      <span slot="label">{{String.fromCharCode(65 + index)}}</span>
                      <a-input 
                        v-model.trim="form.opts[index]"
                        :max-length="15"
                        placeholder="选项长度不能超过15字"></a-input>
                    </a-form-model-item>
                  </a-radio>
              </a-radio-group>
            </template>
            <a-radio-group v-else v-model="form.correct">
              <a-radio value="0">正确</a-radio>
              <a-radio value="1">错误</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="分值" prop="score">
            <a-input v-model.trim="form.score" class="input-score"></a-input> 分
          </a-form-model-item>
          <a-form-model-item label="描述图" prop="path_img">
            <a-upload
              :customRequest="(file) => uploadFile(file, 'detail')"
              :showUploadList="false">
              <div v-if="form.path_img" class="detail-img">
                <img :src="form.img_view" />
                 <a-button type="primary" icon="delete" @click.stop="form.img_view = ''; form.path_img='' "/>
              </div>
              <div class="tip" v-else>
                <a-icon type="cloud-upload" />
                <span>点击上传描述图</span>
              </div>
            </a-upload>
          </a-form-model-item>
          <!-- 重置字段 -->
          <a-form-model-item prop="img_view" v-show="false">
            <a-input v-model="form.img_view"></a-input>
          </a-form-model-item>
          <a-form-model-item prop="opts" v-show="false">
            <a-select v-model="form.opts"></a-select>
          </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-spin>
</template>

<script>
import DPlayer from 'dplayer'
import axios from 'axios'

export default {
  data () {
    return {
      spinning: false,
      levels: [],
      workTypes: [],
      showTypes: false,
      collapse: true,
      basic: {
        sort: 1, // 文件类型： 1-视频、 2-ppt
        name: '',
        levels: [], // 级联数组
        level_type: '', 
        level_id: '',
        level_sub: '',
        wt_id: undefined,
        wt_ids: [],
        detail: '',
        file_img: '', // 封面图-传后台
        cover_view: '', // 前端显示用
        file_path: '',  // 视频文件
        exam_ids: '' // 试题ids
      },
      rules: {
        name: [{ required: true, message: '请输入课件名称', trigger: 'blur'}],
        levels: [{ required: true, message: '请选择课件类型及层级', trigger: 'change'}],
        wt_id: [{ required: true, message: '请选择工种', trigger: 'change' }],
        file_img: [{ required: true, message: '请上传封面图', trigger: 'change' }],
        file_path: [{ required: true, message: '请上传视频文件', trigger: 'change' }],
        exam_ids: [{ required: true, message: '请上传试题', trigger: 'change' }],
      },
      checkRules: {}, // 校验后的规则
      videoProgress: 0,
      list: [],
      pagination: {},
      submitLoading: false,
      // 弹框
      dialog: false,
      confirmLoading: false,
      okBtnText: '确定',
      modalType: 'add',
      form: {
        sort: '1',
        title: '',
        options: '', // 选项
        opts: ['', '', '', ''], // 选项-前端存值
        correct: '',
        score: '',
        img_view: '',
        path_img: '' // 传给后台的图片地址
      },
      modalRules: {
        sort: [{ required: true, message: '请选择该试题类型', trigger: 'change' }],
        title: [{ required: true, message: '请输入试题题目', trigger: 'blur' }],
        score: [{ required: true, message: '请输入该试题的分支', trigger: 'blur' }],
        correct: [{ required: true, message: '请选择该试题的正确答案', trigger: 'change' }],
        value: [{ required: true, message: '请输入试题选项', trigger: 'blur' }] // 选项校验
      }
    }
  },
  watch: {
    'basic.file_img' (val) {
      if (val == '') {
        this.basic.cover_view = ''
      }
    },
    'basic.file_path' (val) {
      if (val == '') {
        this.basic.video_url = ''
      }
    },
    list (val) {
      let quesIds = val.map(item => item.kid)
      this.basic.exam_ids = quesIds.length != 0 ? JSON.stringify(quesIds) : ''
    }
  },
  computed: {
    relativeTypes () {
      let data = this.workTypes.map(item => {
        return { label: item.name, value: item.kid, disabled: false }
      })
      return data
    }
  },
  mounted () {
    this.getOptions()
  },
  methods: {
    // 层级级联=课件类型 / 课件级别
    selectLevel (val, selectedOptions) {
      if (val) {        
        this.basic.level_type = val[0];
        this.basic.level_id = val[1];
        this.basic.level_sub = val.length == 3 ? this.basic.level_sub = val[2] : '' ;
        if (val[1] && selectedOptions[1].tag == 'worktype') {
          this.showTypes = true
        } else {
          this.showTypes = false
          this.basic.wt_id = undefined
          this.basic.wt_ids = []
        }
      }
    },
    // 层级 和 工种选项
    getOptions () {
      axios.all([this.api.allWorkTypes(), this.api.allLevels()])
        .then(axios.spread((res1, res2) => {
          this.workTypes = res1.data.data
          this.levels = res2.data.data
          // 详情
          if (this.$route.query.id) {
            this.getCourseInfo()
          }
        }))
    },
    // 获取课件详情
    getCourseInfo () {
      this.spinning = true
      this.api.getCourseInfo({kid: this.$route.query.id}).then(res => {
        this.spinning = false
        this.basic = Object.assign(this.basic, res.data.data)
        // 课件类型
        this.basic.levels.push(this.basic.level_type, this.basic.level_id)
        if (this.basic.level_sub) {
          this.basic.levels.push(this.basic.level_sub)
        }

        let full_levels = Array.from(this.levels, item => item.sub).flat()
        let course_level = full_levels.find(item => item.kid == this.basic.level_id)
        if (course_level.tag && course_level.tag == 'worktype') {
          this.showTypes = true
        }

        // 课件文件
        if (this.basic.file_path) {          
          this.$nextTick(_ => {
            const dp = new DPlayer({
              container: document.getElementById('dplayer'),
              video: {
                url: res.data.data.video_url
              }
            })
          })
        }
        // 试题
        this.list = this.basic.exams
      }).catch(err => {
        this.spinning = false
      })
    },
    // 上传文件
    uploadFile (file, type) {
      // 视频
      if (type == 'video') {
        this.submitLoading = true
        let _self = this
        // 进度条
        let config = {
          onUploadProgress: function (progressEvent) {
            _self.videoProgress = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          }
        }
        this.api.uploadFile(file.file, type, config).then(res => {
          this.submitLoading = false
          this.basic.file_path = res.data.data.file_path
          this.$nextTick(() => {
            const dp = new DPlayer({
              container: document.getElementById('dplayer'),
              video: {
                url: res.data.data.file_url
              }
            })
            this.videoProgress = 0
          })
        }).catch(err => {
          this.submitLoading = false
          this.videoProgress = 0
        })
      }
      // 封面图
      if (type == 'cover') {        
        this.submitLoading = true
        this.api.uploadFile(file.file).then(res => {
          this.basic.cover_view = res.data.data.file_url // 图片预览
          this.basic.file_img = res.data.data.file_path // 传后台图片地址
          this.submitLoading = false
        }).catch(err => {
          this.submitLoading = false
        })
      }
      // 描述图
      if (type == 'detail') {        
        this.okBtnText = '上传图片中'
        this.confirmLoading = true
        this.api.uploadFile(file.file).then(res => {
          this.form.img_view = res.data.data.file_url // 图片预览
          this.form.path_img = res.data.data.file_path // 传后台图片地址
          this.okBtnText = "确认"
          this.confirmLoading = false
        }).catch(err => {
          this.confirmLoading = false
          this.okBtnText = "确认"
        })
      }
    },
    // 弹框
    showModal (type, id, index) {
      this.dialog = true
      this.modalType = type 
      if (id) {
        this.getQuesInfo(id, index)
      }
      this.$nextTick(() => {
        this.$refs.modalForm.resetFields();
      })
    },
    // 确认弹框
    modalConfirm () {
      this.$refs.modalForm.validate(valid => {
        if (valid) {
          if (this.form.sort == 2) {
            this.form.opts = ['正确', '错误']
          }
          let param = Object.assign({}, this.form)
          param.options = JSON.stringify(this.form.opts)
          this.confirmLoading = true
          if (this.modalType == 'add') {
            this.addQues(param)
          } else {
            param.cocw_id = this.$route.query.id
            this.editQues(param)
          }
        } else {
          return false 
        }
      })
    },
    // 试题
    addQues (param) {
      this.api.addQues(param).then(res => {
        this.confirmLoading = false
        this.dialog = false
        this.$set(param, 'kid', res.data.data.kid)
        this.list.push(param)
      }).catch(err => {
        this.confirmLoading = false
      })
    },
    getQuesInfo (id, index) {
      this.api.getQuesInfo({kid: id}).then(res => {
        this.form = {...res.data.data}
        this.$set(this.form, 'opts', this.form.options)
        this.$set(this.form, 'index', index)
        this.form.options = ''
      })
    },
    editQues (param) {
      this.api.editQues(param).then(res => {
        this.confirmLoading = false 
        this.dialog = false 
        this.list.splice(param.index, 1, param)
      }).catch(err => {
        this.confirmLoading = false
      })
    },
    delQues (id, index) {
      this.list.splice(index, 1)
      // 删除试题接口逻辑需在碰
    },
    // 提交
    submit () {
      this.$refs.basicForm.validate((valid, rules) => {
        if (valid) {
          let _self = this
          this.$confirm({
            title: '友情提示',
            content: '确认提交以上内容？',
            onOk () {
              return new Promise((resolve) => {

                delete _self.basic.exams
                let param = Object.assign({}, _self.basic)
                param.wt_ids = JSON.stringify(param.wt_ids)

                if (_self.$route.query.id) {
                  _self.editCourse(param)
                } else {
                  _self.addCourse(param)                  
                }
                resolve()
              })
            },
            onCancel() {}
          })
        } else {
          let keys = Object.keys(rules);
          this.checkRules = {}
          keys.forEach(item => {
            this.$set(this.checkRules, item, true)
          })
          return false
        }
      })
    },
    addCourse (param) {
      let _self = this
      this.api.addCourse(param).then(res => {
        this.$confirm({
          title: '友情提示',
          content: res.data.msg + '，是否继续添加课件？',
          okText: '继续添加',
          cancelText: '返回列表页',
          onOk () {
            return new Promise((resolve) => {
              _self.$refs.basicForm.resetFields()
              _self.checkRules = []
              _self.list = []
              _self.showTypes = false
              resolve()
            })
          },
          onCancel () {
            return new Promise((resolve) => {
              _self.$router.push('/courseware/courselist')
              resolve()
            })
          }
        })
      }).catch(err => {
      })
    },
    editCourse (param) {
      this.api.editCourse(param).then(res => {
        this.$message.success(res.data.msg)
        this.$router.push('/courseware/courselist')
      })
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.btn-back {
  cursor: pointer;
  display: inline-block;
  padding: 0 6px;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  font-weight: bold;
  margin-top: -5px;
  margin-bottom: 16px;
}
.basic-info {
  /deep/.ant-col-3, /deep/.ant-col-9 {
    min-width: 70px;
  }
  .ant-checkbox-group {
    line-height: 2;
  }
  .relative /deep/.ant-form-item-children {
    display: inline-block;
    width: 100%;
  }
  .btn-collapse {
    position: absolute;
    right: 0;
    height: 40px;
    bottom: 0px;
  }
}
.ant-card {
  height: 100%;
  /deep/.ant-card-head-wrapper {
    height: 64px;
  }
  /deep/.ant-card-body {
    height: calc(100% - 64px);
  }
  /deep/.ant-card-head-title .title-name::before {
    display: inline-block;
    content: '*';
    color: #f5222d;
    margin-right: 4px;
    font-size: 14px;
    line-height: 1;
  }
}
.cover-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    max-height: 160px;
    object-fit: contain;
    border: 1px dashed #d1d1d1;
  }
  .ant-btn {
    margin-top: 10px;
  }
}
.dplayer {
  margin-bottom: 20px;
}
.course-file {
  text-align: center;
  img {
    width: 80%;
    height: auto;
    object-fit: contain; 
    margin-bottom: 20px;
  }
  .ant-progress {
    margin-top: 10px;
  }
}
.exam {
  list-style: none;
  padding: 0;
  > li {
    border: 1px solid #dedede;
    margin-bottom: 15px;
  }
  .list-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 12px;
    background: #fff;
    .seq {
      display: flex;
      flex-direction: column;
      align-items: center;
      .number {
        font-size: 24px;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
      }
      .ant-tag { 
        margin: 0;
      }
    }

    .ques {
      flex: 1;
      padding: 0 12px;
      .ques-title {
        color: #333;
      }
      .ques-detail {
        display: flex;
        padding: 0 10px;
        line-height: 2em;
        margin-top: 10px;
        .options {
          flex: 1;
          list-style: none;
          padding: 0;
        }
        .answer {
          font-size: 16px;
          font-weight: bolder;
          padding: 0 10px;
          background: #d6e0f0;
          color: #000;
        }
      }
    }

    .ques-cover {
      width: 200px;
      height: 140px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .list-action {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #dedede;
    box-shadow:  0px 3px 3px rgba(0,0,0,0.2),
                0 0 0 rgba(0,0,0,0.1) inset;
    span {
      cursor: pointer;
      padding: 0 20px;
      border: 0;
      font-size: 14px;
      height: 36px;
      line-height: 36px;
      border-left: 1px solid #d9d9d9;
      &:hover {
        background: #1890ff;
        color: #fff;
        border-color: transparent;
      }
      &.del-action:hover {
        background: #ff4d4f;
        
      }
    }
  }
}
.ant-empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ant-modal-body {
  .radio-group {
    display: block;
    /deep/.ant-radio-wrapper {
      display: block;
      > span {
        display: block;
        float: left;
        &.ant-radio {
          width: 20px;
          height: 20px;
          margin: 12px 0;
        }
        &:last-child {
          width: calc(100% - 20px);
          vertical-align: middle;
        }
      }
    }
    /deep/.ant-form-item {
      margin-bottom: 5px;
      display: flex;
      .ant-form-item-label {
        width: 40px;
        text-align: left;
      }
      .ant-form-item-control-wrapper {
        flex: 1;
      }
    }
  }
  .input-score {
    width: 65px;
    margin-right: 8px;
  }
  .detail-img {
    position: relative;
    width: 240px;
    height: 140px;
    border: 1px dashed #dedede;
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
    .ant-btn {
      position: absolute;
      top: 5px; 
      right: 5px;
      display: none;
    }
    &:hover {
      .ant-btn {
        display: block;
      }
    }
  }
  .tip {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 140px;
    border: 1px dashed #dedede;
    .anticon-cloud-upload {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
}
.btn-submit {
  display: block;
  width: 240px;
  margin: 20px auto;
}
.is-error {
  border: 1px solid #f5222d !important;
}
.hint {
  font-size: 12px;
  margin-top: 5px;
  color: #a1a1a1;
}
</style>